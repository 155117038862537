<template>
  <div class="vehicleManagePage">
    <!-- 表格区域 -->
    <div class="facilityBox">
      <div class="topbtns">
        <el-button type="success" size="medium" @click="addvehicle()" icon="el-icon-circle-plus-outline">添加</el-button>
        <el-button icon="el-icon-wallet" type="primary" size="medium"
          @click="$router.push('/transport/vehicleManage/importVehicle')">上传车辆表格</el-button>
        <el-button icon="el-icon-wallet" type="primary" size="medium"
          @click="$router.push('/transport/vehicleManage/WLHYimportVehicle')">上传网络货运车辆表格</el-button>
        <el-button icon="el-icon-download" type="primary" size="medium" @click="download">下载车辆表格模板</el-button>
        <el-button icon="el-icon-download" type="primary" size="medium" @click="exportData">导出车辆表格</el-button>
        <el-button icon="el-icon-s-promotion" type="primary" size="medium" @click="BatchBackDraft"
          v-if="vehicleForm.Status == 2">
          退回草稿</el-button>
      </div>
      <el-form :model="vehicleForm" ref="vehicleForm" inline>
        <el-form-item label="车牌号" prop="keyword" label-width="70px">
          <el-input v-model="vehicleForm.VehicleCarNumber" placeholder="请输入车牌号" @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="载重" prop="keyword" label-width="80px" @keyup.enter.native="search()">
          <el-input v-model="vehicleForm.VehicleLoad" placeholder="请输入载重" onkeyup="value=value.replace(/[^\d\.]/g,'')">
          </el-input>
        </el-form-item>
        <el-form-item label="审核状态" prop="IdentityType" label-width="100px">
          <el-select v-model="vehicleForm.Status" placeholder="请选择审核状态" @change="searchChange()">
            <el-option v-for="item in checklist" :key="item.Code" :label="item.Name" :value="item.Code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="认证时间" prop="keyword" label-width="100px">
          <el-date-picker :clearable="false" v-model="vehicleForm.VehicleLicenseValidDate" type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            @change="searchChange()">
          </el-date-picker>
        </el-form-item>
        <div>
          <el-form-item label="驳回原因" prop="Remark" label-width="70px">
            <el-input v-model="vehicleForm.Remark" placeholder="请输入驳回原因" @keyup.enter.native="search()"></el-input>
          </el-form-item>
          <el-form-item label-width="10px">
            <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" size="medium" icon="el-icon-delete" @click="resetForm()">清空</el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-table :data="tableData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading"
        @selection-change="tableSelectionChange">
        <el-table-column type="selection" align="center" width="55" fixed="left" show-overflow-tooltip>
        </el-table-column>
        <el-table-column fixed align="center" type="index" label="序号" width="50" show-overflow-tooltip>
        </el-table-column>
        <el-table-column fixed align="center" prop="VehicleCarNumber" label="车牌号" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarNumberColor" label="车牌颜色" width="80" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="VehicleType" label="车辆类型" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="VehicleLoadPersons" label="核载人数" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="VehiclePowerType" label="能源类型" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="VehicleTotalWeight" label="自重(吨)" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="VehicleLoad" label="载重(吨)" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="VehicleLoadWeight" label="总重(吨)" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="VehicleLength" label="车长(米)" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="VehicleWidth" label="车宽(米)" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="VehicleHeight" label="车高(米)" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="VehicleAxlesNumber" label="轴数" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="VehicleVIN" label="车辆识别代号" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PowerNumber" label="发动机号码" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BrandModelNumber" label="品牌型号" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="VehicleUsage" label="使用性质" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="VehicleMaster" label="所有人" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="VehicleRTP" label="道路运输经营许可证" width="200" show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column align="center" prop="VehicleRTPDate" label="道路运输经营许可证有效期" width="200" show-overflow-tooltip></el-table-column> -->
        <el-table-column align="center" prop="VehicleLicenseValidDate" label="行驶证有效期" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CInsuranceDate" label="交强险到期日" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BInsuranceDate" label="商业险到期日" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="VehicleBuyDate" label="购车日期" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InspectAnnuallyDate" label="年审日期" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="VehicleAttribution" label="车辆归属" width="200"></el-table-column>
        <el-table-column align="center" prop="InNetwork" label="北斗入网校验" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.InNetwork" :type="scope.row.InNetwork == '未入网' ? 'danger' : 'success'">
              {{ scope.row.InNetwork }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="YLCExist" label="平台运力池" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.YLCExist" :type="scope.row.YLCExist == '不存在' ? 'danger' : 'success'">
              {{ scope.row.YLCExist }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Status" label="审核状态" width="120" show-overflow-tooltip fixed="right">
          <template slot-scope="scope">
            <el-tag :type="scope.row.StatusCode == 0 ? 'primary' :
              scope.row.StatusCode == 2 ? 'danger' :
                scope.row.StatusCode == 1 ? 'success' :
                  scope.row.StatusCode == 3 ? 'warning' : 'info'">
              {{ scope.row.Status }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" prop="VerifyDatetime" label="认证时间" width="220"></el-table-column>
        <el-table-column fixed="right" align="center" prop="Remark" label="驳回原因" width="220"></el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="190">
          <template slot-scope="scope">
            <el-button @click="addvehicle(scope.row, pagination.page, pagination.pagesize)" type="primary" size="small"
              title="编辑" icon="el-icon-edit">编辑</el-button>
            <el-button @click="delVehicleInfo(scope.row)" type="danger" size="small" title="删除" icon="el-icon-delete">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="handleCurrentChange" @size-change="sizeChange"
        :current-page.sync="pagination.page" :page-size="pagination.pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import UploadImg from '@/components/commonCmpt/uploadImg'
import { getVehicleInfo, delVehicleInfo } from '@/api/transport/vehicleManage/index'
import { upExport } from "@/api/finance/account/index";
import { GetFileInfo, getDataDict } from '@/api/common/common'
import { BatchDraftCar } from "@/api/auditInfo/certified/index";
export default {
  data() {
    return {
      pagination: { //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 搜索表单
      vehicleForm: {
        VehicleCarNumber: '',
        VehicleLoad: '',
        VehicleLicenseValidDate: '',
        Status: '',
        Remark: ''
      },
      tableData: [], //表格数据
      // 是否禁用提交按钮
      loading: true,
      // 审核状态
      checklist: [
        {
          Code: '',
          Name: '全部'
        }
      ],
      selectList: [],
    }
  },
  methods: {
    //分页数量改变
    sizeChange(e) {
      this.pagination.pagesize = e
      this.getVehicleInfo()
    },
    searchChange() {
      this.search()
    },
    // 浏览器刷新重置参数
    beforeunloadHandler(e) {
      if (this.$route.path == '/transport/vehicleManage/index') {
        // 重置路由
        this.$router.push({
          path: "/transport/vehicleManage/index"
        });
      }
    },
    // 导出表格
    exportData() {
      this.loading = true
      let data = {
        fileType: "车辆导出",
        fileName: "车辆信息",
        CarNumber: this.vehicleForm.VehicleCarNumber,
        VehicleLoad: this.vehicleForm.VehicleLoad,
        TimeStart: this.vehicleForm.VehicleLicenseValidDate[0],
        TimeEnd: this.vehicleForm.VehicleLicenseValidDate[1],
        Status: this.vehicleForm.Status,
        Remark: this.vehicleForm.Remark
      }
      upExport({ Json: JSON.stringify(data) }).then(res => {
        window.location.href = res.pathList[0].pathName
      }).finally(() => {
        this.loading = false
      })
    },
    //下载Excel模板
    download() {
      this.loading = true
      GetFileInfo({ ftType: 1 }).then(res => {
        if (res.data.FTURL == '') {
          this.$message.error('暂未找到该模板，请稍后下载...');
        } else {
          window.location.href = res.data.FTURL
        }
      }).finally(() => {
        this.loading = false
      })
    },
    //搜索
    search() {
      //搜索前初始化page
      this.pagination.page = 1
      this.getVehicleInfo()
    },
    //清空
    resetForm() {
      this.loading = true
      this.vehicleForm = {
        VehicleCarNumber: '',
        VehicleLoad: '',
        VehicleLicenseValidDate: '',
        Status: '',
        Remark: ''
      }
      this.pagination.page = 1
      this.getVehicleInfo()
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true
      this.pagination.page = e
      this.getVehicleInfo()
    },
    //获取车辆列表
    getVehicleInfo() {
      this.loading = true
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        VehicleCarNumber: this.vehicleForm.VehicleCarNumber,
        VehicleLoad: this.vehicleForm.VehicleLoad,
        TimeStart: this.vehicleForm.VehicleLicenseValidDate[0],
        TimeEnd: this.vehicleForm.VehicleLicenseValidDate[1],
        Status: this.vehicleForm.Status,
        Remark: this.vehicleForm.Remark
      }
      getVehicleInfo({ Json: JSON.stringify(params) }).then(res => {
        this.tableData = res.vehicles.map(item => {
          if (item.Status == 0) {
            item.Status = '上传未校验'
          } else if (item.Status == 1) {
            item.Status = '已校验通过'
          } else if (item.Status == 2) {
            item.Status = '校验未通过'
          }
          return item
        })
        this.pagination.total = Number(res.totalRowCount)
      }).finally(() => {
        this.loading = false;
      })
    },
    //删除车辆
    delVehicleInfo(item) {
      this.$confirm('此操作将删除该车辆, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delVehicleInfo({
          id: item.VehicleID
        }).then(res => {
          //获取车辆列表
          this.getVehicleInfo()
        })
      }).catch(() => {
      })
    },
    // 添加/编辑车辆
    addvehicle(item, pageIndex, pagesize) {
      let data = {}
      if (item) {
        data.type = 'edit'
        data.id = item.VehicleID
        data.pageIndex = pageIndex
        data.pagesize = pagesize
        data.VehicleCarNumber = this.vehicleForm.VehicleCarNumber
        data.VehicleLoad = this.vehicleForm.VehicleLoad
        data.VehicleLicenseValidDate = this.vehicleForm.VehicleLicenseValidDate
        data.Status = this.vehicleForm.Status,
          data.Remark = this.vehicleForm.Remark
      } else {
        data.type = 'add'
      }
      this.$router.push({
        path: "/transport/vehicleManage/verify",
        query: data
      })
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.selectList = e;
    },

    //自动审核失败批量处理
    BatchBackDraft() {
      let data =
      {
        Remark: '',
        Details: []
      }

      this.selectList.forEach((item) => {
        let params = {
          CarNumber: item.VehicleCarNumber,
          AscriptionUserID: item.AscriptionUserID,
        };
        data.Details.push(params);
      })
      this.$confirm("确定退回该车辆的审核信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        BatchDraftCar(data).then((res) => {
          this.$message.success("操作成功!");
          this.search();
        })
      })
    },
  },
  created() {
    if (this.$route.query.pageIndex) {
      this.pagination.page = Number(this.$route.query.pageIndex)
      this.pagination.pagesize = Number(this.$route.query.pagesize)
    }
    this.vehicleForm.VehicleCarNumber = this.$route.query.VehicleCarNumber || ''
    this.vehicleForm.VehicleLoad = this.$route.query.VehicleLoad || ''
    this.vehicleForm.VehicleLicenseValidDate = this.$route.query.VehicleLicenseValidDate || ["", ""]
    this.vehicleForm.Status = this.$route.query.Status || ''
    this.vehicleForm.Remark = this.$route.query.Remark || ''
    //获取车辆列表
    this.getVehicleInfo()
    // 获取审核状态
    getDataDict({ type: 56 }).then(res => {
      this.checklist = this.checklist.concat(res.patterSetInfo)
    })
  },
  mounted() {
    // 监听浏览器刷新
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  destroyed() {
    // 移除浏览器刷新
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  components: {
    UploadImg
  }
}
</script>

<style lang="scss">
.vehicleManagePage {
  .el-dialog {
    top: -10%;
  }

  .el-input.is-disabled .el-input__inner {
    color: #000;
  }
}
</style>

<style scoped lang="scss">
@import '../../../../assets/style/variable.scss';

.vehicleManagePage {
  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .el-form-item {
    margin-bottom: 14px;
  }

  .head {
    display: flex;

    .btn {
      vertical-align: top;
      margin: 15px 0 0 16px;
    }
  }

  .vehicleType {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .uploadArea {
      margin-top: 20px;

      .confirmInfoForm {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .hint {
      display: flex;
      justify-content: center;
    }

    .choose {
      display: flex;
      justify-content: center;
    }
  }

  .btns {
    margin-bottom: 20px;
  }

  .addForm {
    display: flex;
    flex-wrap: wrap;

    .el-form-item {
      width: 450px;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }

  .pagination {
    margin-top: 10px;
  }
}
</style>